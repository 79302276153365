import * as React from 'react'
import * as R from 'remeda'
import { oc } from 'ts-optchain'
import { ColumnRow } from '../../../../../UI/GridSystem'
import { Title } from './styledComponents'
import Input from '../../../../../UI/Input'
import { Input as TextInput } from '../../../../../UI/DataFields/Input'
import { DriverDTO, DriverViewDTO, PowerUnitDTO } from '../../../../../../api/origin/business-logic/api'
import { Select } from '../../../../../UI/DataFields/Select'
import { PowerUnitSearch } from '../../../../../UI/DataFields/SearchRequest'
import { DriverSearch } from '../../../../../UI/DataFields/SearchByList/DriverSearch'
import { driverLists } from '../../../../../../services/select/driverLists'
import { isNewId } from '../../../../../../services/DTO'
import { regExpOnlyNumbers } from '../../../../../../services/functions/regExp'
import { generateDriver } from '../../../../../../services/functions/generate/generateDriver'
import { useExpandedItem } from '../../../../../../hooks/useExpandedItem'
import { isDriverFieldRequired } from '../../../../../../services/functions/test/isDriverFieldRequired'
import { useGridItemDriver } from '../../../../../../hooks/useGridItemDriver'

type Props = {}

export const TruckInfo = (props: Props) => {
  const { modifyParentObjectField, modifyParentObject } = useExpandedItem()
  const { driver, isDriverTypeFilled } = useGridItemDriver()

  const isOwnedOperator = driver.driverType === DriverDTO.DriverTypeEnum.OWNEROPERATOR
  const isCompany = driver.driverType === DriverDTO.DriverTypeEnum.COMPANY
  const isDriver = isOwnedOperator || isCompany
  const isCandidateDriverTypeEditable =
    !isDriverTypeFilled &&
    (driver.status === DriverDTO.StatusEnum.CANDIDATEREJECTED || driver.status === DriverDTO.StatusEnum.CANDIDATE)
  const isDriverTypeEditable = isNewId(driver.id) || isCandidateDriverTypeEditable

  return (
    <div style={{ width: '33.3%' }}>
      <Title>{isDriver && 'Driver Info'}</Title>

      <ColumnRow margin={{ bottom: 25 }}>
        <div style={{ width: 150, flexShrink: 0 }}>
          <Select
            disabled={!isDriverTypeEditable}
            title={'Type'}
            required={driver.status !== DriverDTO.StatusEnum.CANDIDATEREJECTED && !isDriverTypeFilled}
            selectedValue={driver.driverType}
            list={
              isCandidateDriverTypeEditable
                ? driverLists.type.filter(_ =>
                    [DriverDTO.DriverTypeEnum.COMPANY, DriverDTO.DriverTypeEnum.OWNEROPERATOR].includes(_.value)
                  )
                : driverLists.type
            }
            onSelect={(driverType: DriverDTO.DriverTypeEnum) =>
              modifyParentObject(updateDriverType(driverType, driver))
            }
          />
        </div>

        {isOwnedOperator && (
          <div style={{ width: '100%', minWidth: 120, marginLeft: 20 }}>
            <PowerUnitSearch
              required={isDriverFieldRequired(driver).powerUnitId}
              title={'Owned Truck'}
              value={driver.powerUnit}
              onChange={(powerUnit: PowerUnitDTO) =>
                modifyParentObject({ ...driver, powerUnit, powerUnitId: oc(powerUnit).id() })
              }
            />
          </div>
        )}

        {isOwnedOperator && (
          <div style={{ width: '100%', minWidth: 120, marginLeft: 20 }}>
            <DriverSearch
              title={'Company'}
              selectedDriverId={oc(driver).parentId()}
              filterListResultFunction={_driver =>
                _driver.status !== DriverDTO.StatusEnum.TERMINATED &&
                _driver.driverType === DriverDTO.DriverTypeEnum.VENDOR &&
                _driver.id !== driver.id
              }
              onChange={(parent: DriverDTO) => {
                const parentId = oc(parent).id()

                if (driver.driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR && parentId) {
                  return modifyParentObject({ ...driver, parentId, w9DocumentFile: undefined })
                }

                return modifyParentObject({ ...driver, parentId })
              }}
            />
          </div>
        )}
      </ColumnRow>

      {(isOwnedOperator || isCompany) && (
        <ColumnRow margin={{ bottom: 25 }}>
          <div style={{ width: '100%', minWidth: 150 }}>
            <TextInput
              title={'E-ZPass'}
              minLength={11}
              maxLength={11}
              value={driver.ezPassTagNumber}
              placeholder={'Type here'}
              regExp={regExpOnlyNumbers}
              onChange={modifyParentObjectField('ezPassTagNumber')}
            />
          </div>

          <div style={{ width: '100%', minWidth: 150, marginLeft: 20 }}>
            <TextInput
              title={'Fuel Card'}
              minLength={19}
              maxLength={19}
              value={driver.fuelCardNumber}
              placeholder={'Type here'}
              regExp={regExpOnlyNumbers}
              onChange={modifyParentObjectField('fuelCardNumber')}
            />
          </div>
        </ColumnRow>
      )}

      <div style={{ display: 'flex', height: isOwnedOperator ? 100 : 135 }}>
        <Input
          isTextArea={true}
          maxLength={1024}
          title={'Notes'}
          value={driver.notes}
          onChange={modifyParentObjectField('notes')}
        />
      </div>
    </div>
  )
}

const updateDriverType = (driverType: DriverDTO.DriverTypeEnum, driver: DriverViewDTO): DriverViewDTO => {
  let resultDriver: DriverViewDTO = R.clone({ ...driver, driverType })
  const newDriver = generateDriver()

  switch (driverType) {
    case DriverDTO.DriverTypeEnum.COMPANY: {
      resultDriver = { ...newDriver, ...resultDriver }
      resultDriver.mobileApp = resultDriver.mobileApp !== undefined ? resultDriver.mobileApp : true
      const omitProps: (keyof DriverViewDTO)[] = [
        'powerUnit',
        'powerUnitId',
        'parentId',
        'taxId',
        'leaseDocumentFile',
        'w9DocumentFile'
      ]

      // @ts-ignore
      resultDriver = R.omit(resultDriver, omitProps)
      break
    }
    case DriverDTO.DriverTypeEnum.OWNEROPERATOR: {
      resultDriver = { ...newDriver, ...resultDriver }
      resultDriver.mobileApp = resultDriver.mobileApp !== undefined ? resultDriver.mobileApp : true
      const omitProps: (keyof DriverViewDTO)[] = ['w4DocumentFile']
      // @ts-ignore
      resultDriver = R.omit(resultDriver, omitProps)
      break
    }
    case DriverDTO.DriverTypeEnum.VENDOR:
    case DriverDTO.DriverTypeEnum.OUTSOURCE: {
      resultDriver = { ...newDriver, ...resultDriver }
      const omitProps: (keyof DriverViewDTO)[] = [
        'mobileApp',
        'birthday',
        'eld',
        'eldNumber',
        'endorsement',
        'endorsementExpDate',
        'licenseAddress',
        'licenseDocumentFile',
        'licenseBackDocumentFile',
        'licenseExpDate',
        'licenseIssueDate',
        'licenseIssueStateId',
        'licenseNumber',
        'medicalExamCardDocumentFile',
        'medicalExamCardExpDate',
        'millageRestriction',
        'operationalPreference',
        'parentId',
        'portCredentialsDocumentFile',
        'portCredentialsExpDate',
        'powerUnit',
        'powerUnitId',
        'twicDocumentFile',
        'twicExpDate',
        'ezPassTagNumber',
        'fuelCardNumber',
        'taxId',
        'applicationDocumentFile',
        'w4DocumentFile',
        'leaseDocumentFile',
        'i9DocumentFile'
      ]

      if (driverType === DriverDTO.DriverTypeEnum.OUTSOURCE) {
        omitProps.push('w9DocumentFile')
      }

      // @ts-ignore
      resultDriver = R.omit(resultDriver, omitProps)
      break
    }
    default:
  }

  return resultDriver
}
