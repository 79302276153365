import * as React from 'react'
import styled from 'styled-components'
import theme from '../../../../../styles/theme'
import { ArrowActionButton } from '../../../../UI/Buttons/ArrowActionButton'
import { driverDirectory } from '../../../../../services/DTO/driver/directory'
import { DriverDTO, DriverViewDTO } from '../../../../../api/api'
import { preventEvent } from '../../../../../services/functions/basic'
import { useExpandedItem } from '../../../../../hooks/useExpandedItem'
import { useGridItemDriver } from '../../../../../hooks/useGridItemDriver'

type Props = {
  attributes?: any
}

export const CandidateStatusColumn = (props: Props) => {
  const { attributes } = props
  const { driver, isExpanded } = useGridItemDriver()
  // @ts-ignore
  const { modifyParentObjectField } = isExpanded ? useExpandedItem() : {}
  const status = driver.status
  const text = driverDirectory.status[status]
  const color = driverDirectory.statusColor[status]

  if (!modifyParentObjectField) {
    return <ArrowActionButton text={text} color={color} attributes={attributes} />
  }

  const currStatusIndex = driverDirectory.sortedCandidateStatuses.findIndex(_status => _status === status)
  const prevStatus = driverDirectory.sortedCandidateStatuses[currStatusIndex - 1]
  const nextStatus = driverDirectory.sortedCandidateStatuses[currStatusIndex + 1]
  const changeStatus = (_status: DriverViewDTO.StatusEnum) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventEvent(event)
    return modifyParentObjectField('status')(_status)
  }
  const onLeftArrowClick = prevStatus ? changeStatus(prevStatus) : undefined
  let onRightArrowClick = nextStatus ? changeStatus(nextStatus) : undefined

  if (!driver.driverType) {
    const candidateStatusIndex = driverDirectory.sortedCandidateStatuses.findIndex(
      _status => _status === DriverDTO.StatusEnum.CANDIDATE
    )

    if (currStatusIndex + 1 > candidateStatusIndex) {
      onRightArrowClick = undefined
    }
  }

  return (
    <>
      {status !== DriverViewDTO.StatusEnum.CANDIDATEREJECTED && (
        <RejectButton
          children={'Reject'}
          style={{ marginRight: 8 }}
          onClick={changeStatus(DriverViewDTO.StatusEnum.CANDIDATEREJECTED)}
        />
      )}
      <ArrowActionButton
        text={text}
        color={color}
        attributes={attributes}
        onLeftArrowClick={onLeftArrowClick}
        onRightArrowClick={onRightArrowClick}
      />
    </>
  )
}

export const RejectButton = styled.div`
  width: auto !important;
  height: 26px;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: white;
  border-radius: 4px;
  background: ${theme.colors.defaultRed};
  padding: 0px 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`
