import * as React from 'react'
import { Column, ColumnRow, GridColumn } from '../../../../../../UI/GridSystem'
import { Date, Title } from '../styledComponents'
import { Input } from '../../../../../../UI/DataFields/Input'
import DateField from '../../../../../../UI/DatePicker/DateField'
import { DateTypes } from '../../../../../../UI/DatePicker/Interfaces'
import { Select } from '../../../../../../UI/DataFields/Select'
import { driverLists } from '../../../../../../../services/select/driverLists'
import { DriverViewDTO } from '../../../../../../../api/origin/business-logic'
import { isNewId } from '../../../../../../../services/DTO'
import { FieldContainer } from '../../../../../../UI/DataFields/FieldContainer'
import { ButtonToggle } from '../../../../../../UI/DataFields/Select/styles'
import { regExpOnlyNumbers } from '../../../../../../../services/functions/regExp'
import { SecurityInput } from '../../../../../../UI/DataFields/Input/inputTypes/SecurityInput'
import { ViewingObjectFileUploader } from '../../../../../../UI/DataFields/FileUploader/ViewingObjectFileUploader'
import { FileVersionDTO } from '../../../../../../../api/origin/document-service'
import { useExpandedItem } from '../../../../../../../hooks/useExpandedItem'
import { isDriverFieldRequired } from '../../../../../../../services/functions/test/isDriverFieldRequired'
import { useGridItemDriver } from '../../../../../../../hooks/useGridItemDriver'

type Props = {}

export const PersonalInfo = (props: Props) => {
  const { modifyParentObjectField, modifyParentObject } = useExpandedItem()
  const { driver, isDriverTypeFilled, isSimpleValidation } = useGridItemDriver()
  const {
    id,
    driverType,
    firstName,
    middleName,
    lastName,
    operationalPreference,
    birthday,
    name,
    mobileApp,
    taxId,
    hasSSN,
    ssn
  } = driver
  const isVendor = driverType === DriverViewDTO.DriverTypeEnum.VENDOR
  const isOutsource = driverType === DriverViewDTO.DriverTypeEnum.OUTSOURCE
  const isOwnedOperator = driverType === DriverViewDTO.DriverTypeEnum.OWNEROPERATOR
  const isCompany = driverType === DriverViewDTO.DriverTypeEnum.COMPANY
  const isDriver = isCompany || isOwnedOperator
  const title = isDriverTypeFilled ? (isDriver ? 'Personal Info' : 'Company Info') : ''

  return (
    <div style={{ width: '33.3%', paddingRight: 40 }}>
      <Title>{title}</Title>

      <GridColumn>
        <Column columns={10} isGrid={true} flexBasis={'auto'} margin={{ right: 20 }}>
          {isDriverTypeFilled && !isDriver && (
            <>
              <div style={{ marginBottom: 25 }}>
                <Input
                  title={'Company Name'}
                  disabled={(isVendor || isOutsource) && !isNewId(id)}
                  required={!isSimpleValidation}
                  maxLength={200}
                  value={name}
                  onChange={modifyParentObjectField('name')}
                />
              </div>
              {isVendor && (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 160px',
                    gridColumnGap: 20,
                    marginBottom: 25
                  }}
                >
                  <Input
                    title={'EIN / Tax ID'}
                    required={!isSimpleValidation}
                    regExp={regExpOnlyNumbers}
                    value={taxId}
                    minLength={9}
                    maxLength={9}
                    onChange={modifyParentObjectField('taxId')}
                  />
                  <ViewingObjectFileUploader
                    uploadingFileId={driver.id + ' driver.proofEinTaxIdDocumentFile'}
                    title={'Copy of EIN Proof'}
                    required={!isSimpleValidation}
                    file={driver.proofEinTaxIdDocumentFile}
                    onFileUploaded={(proofEinTaxIdDocumentFile, modifiedObject) => ({
                      ...modifiedObject,
                      proofEinTaxIdDocumentFile
                    })}
                    onClearFile={() => modifyParentObjectField('proofEinTaxIdDocumentFile')(undefined)}
                    historyProps={{ entityId: driver.id, type: FileVersionDTO.TypeEnum.VENDORPROOFEINTAXID }}
                  />
                </div>
              )}
            </>
          )}
          <ColumnRow margin={{ bottom: 25 }}>
            <Input
              title={'First Name'}
              maxLength={32}
              required={isSimpleValidation || isDriver}
              value={firstName}
              onChange={modifyParentObjectField('firstName')}
            />
          </ColumnRow>
          {isDriver && (
            <ColumnRow margin={{ bottom: 25 }}>
              <Input
                title={'Middle Name'}
                maxLength={64}
                value={middleName}
                onChange={modifyParentObjectField('middleName')}
              />
            </ColumnRow>
          )}
          <ColumnRow margin={{ bottom: 25 }}>
            <Input
              title={'Last Name'}
              maxLength={64}
              required={isSimpleValidation || isDriver}
              value={lastName}
              onChange={modifyParentObjectField('lastName')}
            />
          </ColumnRow>
          {(isOwnedOperator || isCompany) && (
            <SecurityInput
              required={!isSimpleValidation && !hasSSN && isDriverFieldRequired(driver).ssn}
              requiredMarker={isDriverFieldRequired(driver).ssn}
              title={'SSN'}
              maxLength={9}
              minLength={9}
              regExp={regExpOnlyNumbers}
              filled={hasSSN}
              value={ssn}
              onChange={value => modifyParentObject({ ...driver, ssn: value, hasSSN: true })}
              onClear={() => modifyParentObject({ ...driver, ssn: '', hasSSN: false })}
            />
          )}
        </Column>

        {isDriver && (
          <Column columns={8} isGrid={true} flexBasis={'auto'}>
            <ColumnRow margin={{ bottom: 25 }}>
              <Select
                title={'Operational Preference'}
                required={!isSimpleValidation}
                selectedValue={operationalPreference}
                list={driverLists.operationalPreference}
                onSelect={modifyParentObjectField('operationalPreference')}
              />
            </ColumnRow>
            <Date rows={11} margin={{ bottom: 25 }}>
              <DateField
                title={'Date of Birth'}
                required={isDriverFieldRequired(driver).birthday}
                date={birthday}
                dateType={DateTypes.DateISOString}
                placeholder={'MM/DD/YYYY'}
                onUpdate={modifyParentObjectField('birthday')}
              />
            </Date>
            <FieldContainer title={'Mobile App'}>
              <ButtonToggle onClick={() => modifyParentObjectField('mobileApp')(!mobileApp)} active={mobileApp} />
            </FieldContainer>
          </Column>
        )}
      </GridColumn>
    </div>
  )
}
